<template>
    <user-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <create-consultation-dialog @saved="getConsultations" />
<!--                <v-btn @click="$router.push({name: 'dashboard.consultations.create'})">Create</v-btn>-->
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="consultations"
            :items-per-page="15"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import Consultation from "../../../models/Consultation";
    import CreateConsultationDialog from "../../../components/dashboard/CreateConsultationDialog";

    export default {
        name: "users-index",
        components: {CreateConsultationDialog, UserDashboardLayout},
        data: function () {
            return {
                consultations: [],
                loading: false,
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Starts at', value: 'starts_at' },
                    { text: 'Ends at', value: 'ends_at' },
                    { text: 'Duration', value: 'duration' },
                ],
            }
        },
        methods: {
            handleClickRow(plan) {
                this.$router.push({name: 'admin.price-plans.edit', params: {id: plan.id}})
            },
            async getConsultations() {
                this.loading = true
                let consultations = new Consultation();
                this.consultations = await consultations.where('user_id', this.$auth.user().id).get()
                this.loading = false
            }
        },
        async mounted() {
            await this.getConsultations()
        },
    }
</script>

<style scoped>

</style>
