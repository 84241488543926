<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="800"
    >
        <template v-slot:activator="{ props }">
            <v-btn
                color="primary"
                v-bind="props"
                @click="dialog=true"
            >
                Create Consultation
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                New Consultation
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-date-picker
                            v-model="date"
                            class="mt-4"
                        ></v-date-picker>
                    </v-col>
                    <v-col>
                        <v-time-picker v-model="time"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    @click="dialog = false"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green-darken-1"
                    variant="text"
                    @click="handleCreateConsultation"
                >
                    Agree
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


import Consultation from "../../models/Consultation";

export default {
    name: "CreateConsultationDialog",
    data: () => ({
        dialog: false,
        date: '2018-03-02',
        time: '01:00',
        dateTime: null,
    }),
    methods: {
        async handleCreateConsultation() {
            this.dateTime = this.date + ' ' + this.time
            let consultation = new Consultation({
                starts_at: this.dateTime,
                ends_at: this.dateTime,
                user_id: this.$auth.user().id,
                company_id: this.$auth.user().company.id
            })
            await consultation.save()
            this.$emit('saved')
            this.dialog = false
        }
    }
}
</script>

<style scoped>

</style>
